<template>
  <div>
    <v-toolbar dense dark color="primary">
      <v-toolbar-title><h4 class="font-weight-light">INVENTORY REPORT</h4>
      </v-toolbar-title>
    </v-toolbar>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-uppercase">
            Branch
          </th>
          <th class="text-uppercase">
            Date
          </th>
          <th class="text-uppercase">
            Particulars
          </th>
          <th class="text-uppercase">
            Model
          </th>
          <th class="text-uppercase">
            Total Quantity
          </th>
          <th class="text-uppercase">
            Used
          </th>
          <th class="text-uppercase">
            Remaining
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="item in particulars_items"
          :key="item.id"
        >
          <td>
            {{ item.branch }}
          </td>
          <td>
            {{ item.date }}
          </td>
          <td>
            {{ item.particulars }}
          </td>
          <td>
            {{ item.model }}
          </td>
          <td>
            {{ item.total }}
          </td>
          <td>
            <v-chip
              @click="open_details(item)">
              {{ item.used }}
            </v-chip>
          </td>
          <td>
            {{ item.remaining }}
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog v-model="officers_dialog" persistent max-width="80%">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">LIST OF ASSIGNED IN {{details}} </h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="details_data"
          loading-text="Loading... Please wait"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.date }}</td>
              <td>
                {{ item.district_id!=null?'DISTRICT':
                (item.member_id!=null?'MEMBER':'OTHERS')}}
              </td>
              <td>
                {{ item.member_id!=null?
                item.member.last_name+', '+item.member.first_name
                +' '+item.member.middle_name:''}}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
      <v-btn color="error" @click="officers_dialog = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiAccountSearchOutline} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountSearchOutline,
        },
      }
    },
    data() {
      return {
        particulars_id: '',
        details: '',

        officers_dialog: false,
        saving: false,
        alert: false,
        alert_message: '',

        quantity: '',

        particulars_items: [],

        row: null,
        isLoading: false,
        search: '',
        search_items: [],

        member_id: null,
        district_id: null,
        location: null,
        date: '',

        details_data: [],
        headers: [
          {text: 'LAF #', value: 'position'},
          {text: 'Type', value: 'members.first_name'},
          {text: 'Model', value: 'members.first_name'},
          {text: 'Name', value: 'date_started'},
        ],
      }
    },
    created() {
      this.list_of_all_inventory({
        branch_id: this.branch_id
      })
        .then(response => {
          this.particulars_items = response.data
        })
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['user_id', 'position', 'branch_id']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('inventory', ['list_of_all_inventory',]),
      open_details(items) {
        this.details = items.particulars
        this.officers_dialog = true
        this.details_data = items.used_data
      },
    }
  }
</script>
